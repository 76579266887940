import React from 'react'

function Promociones() {
    return (
        <>
        <section className="experiencia">
            <h1 className="text-center experiencia__h3">Ofertas y Promociones</h1>
            <br/>
        <br/>
        </section>
        
        <br/>
        <br/>

        
        <section className="section-top-ten">
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                
                <iframe className="iframe-mega" title="promociones" src="https://q.megatravel.com.mx/tools/ofertas-viaje.php?&txtColor=000&aColor=000&ahColor=5a7770&thBG=5a7770&thTxColor=fff&ff=7" width="100%" height="1200" border="0"align="left" allowtransparency="true" frameborder="0"> </iframe>
                </div> 
            </div> 
        </div>   
        </section>  
        
        </>
    )
}

export default Promociones
